import Vue from 'vue'
import Vuex from 'vuex'
import vuexRouterState from './vuexRouterState'
import vuexMiddlewares from './vuexMiddlewares'

import formStore from '@/components/form/store'
import tableStore from '@/components/table/store'

import appStore from '@/modules/app/store'
import callsStore from '@/modules/calls/store'
import uraStore from '@/modules/uras/store'
import authStore from '@/modules/auth/store'
import soundStore from '@/modules/sounds/store'
import queuesStore from '@/modules/queues/store'
import agentsStore from '@/modules/agents/store'
import trunkingStore from '@/modules/trunkings/store'
import dashboardStore from '@/modules/dashboards/dashboardStore'
import channelStore from '@/modules/dashboards/channelStore'
import attendanceStore from '@/modules/attendances/attendanceStore'
import extensionsStore from '@/modules/extensions/store'
import preferencesStore from '@/modules/preferences/store'
import lcrProfilesStore from '@/modules/lcrProfiles/store'
import profilersStore from '@/modules/profilers/store'
import pauseReasonsStore from '@/modules/pauses/store'
import entryNumbersStore from '@/modules/entryNumbers/store'
import extensionModelsStore from '@/modules/extensionModels/store'
import userPermissionsStore from '@/modules/userPermissions/store'
import dialPermissionsStore from '@/modules/dialPermissions/store'
import botFlowStore from '@/modules/botFlow/store'
import denylistStore from '@/modules/denyLists/store'

Vue.use(Vuex)

let store = null

export default (router) => {
  if (!store) store = createStore(router)
  return store
}

const createStore = (router) => {
  const modules = {
    appStore,
    uraStore,
    authStore: authStore(router),
    formStore,
    callsStore,
    tableStore,
    soundStore,
    queuesStore,
    agentsStore,
    channelStore,
    botFlowStore,
    trunkingStore,
    profilersStore,
    dashboardStore,
    attendanceStore,
    extensionsStore,
    lcrProfilesStore,
    preferencesStore,
    pauseReasonsStore,
    entryNumbersStore,
    extensionModelsStore,
    userPermissionsStore,
    dialPermissionsStore,
    denylistStore
  }

  return new Vuex.Store({
    modules,

    plugins: [
      vuexRouterState(router),
      vuexMiddlewares(modules)
    ]
  })
}
