export const QUEUECALLER_JOIN = 'QUEUECALLER_JOIN'
export const QUEUEMEMBER_CALLCONNECT = 'QUEUEMEMBER_CALLCONNECT'
export const QUEUECALLER_ABANDON = 'QUEUECALLER_ABANDON'
export const QUEUEMEMBER_CALLEND = 'QUEUEMEMBER_CALLEND'
export const QUEUEMEMBER_CALLSTART = 'QUEUEMEMBER_CALLSTART'
export const QUEUEMEMBER_CALLREJECT = 'QUEUEMEMBER_CALLREJECT'
export const QUEUEMEMBER_CALLATTEMPT = 'QUEUEMEMBER_CALLATTEMPT'
export const QUEUEMEMBER_CALLDUMP = 'QUEUEMEMBER_CALLDUMP'
export const DIALER_CALLCONNECT = 'DIALER_CALLCONNECT'
export const DIALER_CALLSTART = 'DIALER_CALLSTART'
export const DIALER_CALLEND = 'DIALER_CALLEND'

export const IVR_START = 'IVR_START'
export const IVR_PLAYBACK = 'IVR_PLAYBACK'
export const IVR_SAYDIGITS = 'IVR_SAYDIGITS'
export const IVR_SAYNUMBER = 'IVR_SAYNUMBER'
export const IVR_RUNCODE = 'IVR_RUNCODE'
export const IVR_WEBSERVICE = 'IVR_WEBSERVICE'
export const IVR_MENUSELECT = 'IVR_MENUSELECT'
export const IVR_TEXTTOSPEECH = 'IVR_TEXTTOSPEECH'
export const IVR_SPEECHTOTEXT = 'IVR_SPEECHTOTEXT'
export const IVR_READ = 'IVR_READ'
export const IVR_READDIGIT = 'IVR_READDIGIT'
export const IVR_TRANSFERLOCAL = 'IVR_TRANSFERLOCAL'
export const IVR_TRANSFERTOAGENT = 'IVR_TRANSFERTOAGENT'
export const IVR_TRANSFEREXTERNAL = 'IVR_TRANSFEREXTERNAL'
export const IVR_TRANSFERQUEUE = 'IVR_TRANSFERQUEUE'
export const IVR_TRANSFERTOURA = 'IVR_TRANSFERTOURA'
export const IVR_HANGUP = 'IVR_HANGUP'

export const ROUTE_CALLS_LIST = 'ROUTE_CALLS_LIST'
export const ROUTE_CALLS_BY_NUMBER = 'ROUTE_CALLS_BY_NUMBER'
export const ROUTE_CALLS_BY_DURATION = 'ROUTE_CALLS_BY_DURATION'
export const ROUTE_CALLS_BY_CARRIER = 'ROUTE_CALLS_BY_CARRIER'
export const ROUTE_CALLS_BY_LOCALITY = 'ROUTE_CALLS_BY_LOCALITY'
export const ROUTE_CALLS_BY_TRUNKING = 'ROUTE_CALLS_BY_TRUNKING'
export const ROUTE_CALLS_BY_QUEUE = 'ROUTE_CALLS_BY_QUEUE'
export const ROUTE_CALLS_BY_TIME = 'ROUTE_CALLS_BY_TIME'
export const ROUTE_CALLS_BY_TYPE = 'ROUTE_CALLS_BY_TYPE'
export const ROUTE_CALLS_BY_ABANDON = 'ROUTE_CALLS_BY_ABANDON'
export const ROUTE_CALLS_BY_PERFORMANCE = 'ROUTE_CALLS_BY_PERFORMANCE'
export const ROUTE_ATTENDANCE_AGENTS = 'ROUTE_ATTENDANCE_AGENTS'
export const ROUTE_ATTENDANCE_IVR = 'ROUTE_ATTENDANCE_IVR'

export const IVR_TAG = 'IVR_TAG'
